<template>
    <el-dialog
        width="600px"
        top="40px"
        :title="$t('employees.add_absence')"
        :visible.sync="modalVisible"
        :before-close="closeModal"
        :close-on-click-modal="false"
        @closed="clearData"
    >
        <el-form ref="formData" :model="formData" label-position="top" :rules="rules" class="px-16">
            <el-form-item :label="$t('employees.employee')" prop="employeeUuid">
                <EmployeesDropdown
                    ref="employeesDropdown"
                    withoutQuerySearch
                    customClass="w-full"
                    @selected="formData.employeeUuid = $event"
                />
            </el-form-item>

            <el-form-item :label="$t('employees.absence_type')" prop="title">
                <SelectAbsenceType @change="selectTypeChanged" />
            </el-form-item>

            <el-form-item :label="$t('employees.absence_note')">
                <el-input
                    v-model="formData.note"
                    type="textarea"
                    :rows="3"
                />
            </el-form-item>

            <el-form-item :label="$t('employees.absence_period')">
                <el-date-picker
                    v-model="formData.dateRange"
                    popper-class="modal-add-absence"
                    type="datetimerange"
                    :clearable="false"
                    align="center"
                    :editable="true"
                    value-format="timestamp"
                    format="dd.MM.yyyy HH:mm"
                    :picker-options="pickerOptions"
                    @change="datePicked"
                />
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('adding')" @click="addAbsence">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { DatePicker } from 'element-ui';

Vue.use(DatePicker);

export default {
    components: {
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
        SelectAbsenceType: () => import(/* webpackChunkName: "SelectAbsenceType" */ './components/SelectAbsenceType'),
    },

    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            formData: {
                title:        'Semester',
                employeeUuid: null,
                note:         null,
                dateRange:    [
                    this.$dayjs().hour(0).minute(0).second(0),
                    this.$dayjs().add(3, 'day').hour(0).minute(0).second(0),
                ],
            },
            rules: {
                employeeUuid: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                title:        [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                addressUuid:  [{ required: true, message: this.$t('common.required'), trigger: 'change' }],
            },
            pickerOptions: {
                firstDayOfWeek: 1,
                format:         'dd.MM.yyyy HH:mm',
            },
        };
    },

    methods: {
        async addAbsence() {
            await new Promise(resolve => this.$refs.formData.validate(valid => valid && resolve()));
            this.$wait.start('adding');
            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/absence`, {
                    title:            this.formData.title,
                    note:             this.formData.note,
                    start_absence_at: this.formData.dateRange[0].unix(),
                    end_absence_at:   this.formData.dateRange[1].unix(),
                    employee_uuid:    this.formData.employeeUuid,
                });
                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
                this.$wait.end('adding');
            } catch (errror) {
                this.$wait.end('adding');
            }
        },

        closeModal() {
            axios.cancelAll();
            this.$emit('close');
        },

        clearData() {
            this.$refs.employeesDropdown.resetSelection();
            this.formData = {
                title:        'Semester',
                employeeUuid: null,
                note:         null,
                dateRange:    [
                    this.$dayjs().second(0),
                    this.$dayjs().add(3, 'day').second(0),
                ],
            };
        },

        datePicked() {
            this.formData.dateRange = [
                this.$dayjs(this.formData.dateRange[0]).second(0),
                this.$dayjs(this.formData.dateRange[1]).second(0),
            ];
        },

        selectTypeChanged(type) {
            this.formData.title = type;
        },
    },
};
</script>
<style>
.modal-add-absence .el-picker-panel__footer .el-button:nth-child(1) {
    display: none !important;
}

.el-select--small {
    width: 100%;
}
</style>
